var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box_container"},[_c('div',{staticClass:"box_item"},[_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"name"},[_c('span',[_vm._v("用户姓名: "+_vm._s(_vm.obj.name))]),_c('span',{style:(_vm.state == 0
                ? 'color:#5038FA'
                : _vm.state == 1
                ? 'color:red'
                : _vm.state == 2
                ? 'color:green'
                : _vm.state == 3
                ? 'color:#e8b501'
                : '')})]),_c('div',{staticClass:"phone"},[_vm._v(" 用户手机号: "+_vm._s(_vm.obj.mobile ? _vm.obj.mobile : "暂未填写")+" ")]),_c('div',{staticClass:"id"},[_vm._v(" 用户身份证号: "+_vm._s(_vm.obj.idCard ? _vm.obj.idCard : "暂未填写")+" ")])])]),_c('div',{staticClass:"btn_item"},[_c('van-button',{attrs:{"color":"linear-gradient(0deg, #6EAEFC, #6875F1)","round":"","to":{
          path: '/fangTan',
          query: { id: _vm.obj.id, type: 1 },
        }}},[_vm._v(" 查看 ")]),_c('van-button',{attrs:{"color":"linear-gradient(0deg, #0ECDE1, #2BD79F)","round":"","to":{
          path: '/fangTan',
          query: { id: _vm.obj.id, type: 2 },
        }}},[_vm._v(" 访谈 ")]),_c('van-button',{attrs:{"color":"linear-gradient(0deg, #FF9A31, #FFD385)","round":"","to":{
          path: '/fangTan',
          query: { id: _vm.obj.id, type: 3 },
        }}},[_vm._v(" 合同 ")]),_c('van-button',{attrs:{"color":"linear-gradient(0deg, #84F5DE, #56E8F2, #4CC5F8)","round":"","to":{
          path: '/fangTan',
          query: { id: _vm.obj.id, type: 4 },
        }}},[_vm._v(" 支付 ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }