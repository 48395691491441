<template>
  <div class="customer_container">
    <van-nav-bar title="客户管理" safe-area-inset-top placeholder />
    <div class="search">
      <van-search
        show-action
        shape="round"
        label=""
        background="#f0f0f0"
        placeholder="请输入客户姓名或客户手机号"
        @search="onSearch"
        @input="input"
        v-model="value"
      >
        <template #action>
          <div @click="value ? onSearch() : ''" class="btnSearch">搜索</div>
        </template>
      </van-search>
      <!-- <van-search v-model="value" show-action shape="round" label="" placeholder="请输入搜索关键词" @search="onSearch">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search> -->
    </div>
    <!-- <van-tabs
      v-model="active"
      color="#4731FA"
      title-active-color="#4731FA"
      sticky
      @click="onTabs"
    > -->
    <!-- <van-tab title="已签约"> -->
    <div class="box">
      <lazy-component>
        <div v-for="(item, index) in list" :key="index">
          <com-box :state="active" :obj="item"></com-box>
        </div>
      </lazy-component>
    </div>
    <!-- </van-tab> -->
    <!-- <van-tab title="未签约">
        <div class="box">
         
        </div>
      </van-tab>
      <van-tab title="洽谈中">
        <div class="box">
        
        </div>
      </van-tab>
      <van-tab title="合同临近">
        <div class="box">
          
        </div>
      </van-tab> -->
    <div class="page" v-if="total > 1">
      <van-pagination
        v-model="currentPage"
        :total-items="total"
        :show-page-size="3"
        :page-count ="pages"
        force-ellipses
        @change="change"
      />
    </div>
    <div v-else>
      <van-empty description="暂无内容" />
    </div>
    <!-- </van-tabs> -->
  </div>
</template>

<script>
import comBox from "./components/box.vue";
import { getCustomerList } from "@/api";
export default {
  name: "",
  components: {
    comBox,
  },
  data() {
    return {
      active: 0,
      value: "",
      title: "",
      formData: {
        pageSize: 5,
        // signStatus: 0,
      },
      signStatus: 0,
      list: [],
      total: null,
      currentPage: 1,
      pages : 0 ,
    };
  },
  created() {
    this.submit();
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    // active:{
    //   handler(n,o){
    //     console.log(n,o);
    //   },
    //   immediate:true
    // }
  },
  // 方法集合
  methods: {
    onSearch() {
      //搜索
      this.formData.name = this.value;
      this.submit();
    },
    // onTabs(name, title) {
    //   console.log(name, title);
    //   this.signStatus = name;
    //   // this.submit()
    // },
    change(e) {
      this.formData.pageNo = e;
      this.submit();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    submit() {
      //按条件查询提交
      let that = this
      getCustomerList(that.formData).then((res) => {
        if (res.data.success) {
          that.total = res.data.result.total || 0 ;
          that.list = res.data.result.records;
         that.pages  = res.data.result.pages;
        }
      });
    },
    input(e) {
      if (e == "") {
        this.formData.name = "";
        this.submit();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.customer_container {
  height: 100vh;
  background-color: #f0f0f0;
}

/deep/ .van-nav-bar {
  background: linear-gradient(-90deg, #5038fa, #1476ff);

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-search__content {
  border: 1px solid #5038fa;
}

/deep/ .btnSearch {
  position: relative;
  margin-left: -60px;
  background: linear-gradient(-90deg, #5038fa, #1476ff);
  z-index: 99;
  text-align: center;
  color: white;
  border-radius: 0 18px 18px 0;
  border: 1px solid #5038fa;
}

/deep/ .van-tabs__content {
  margin-bottom: 14px;
}

.box {
  background-color: #f0f0f0;
  padding-bottom: 30px;
}
.page {
  padding-bottom: 60px;
}
</style>
