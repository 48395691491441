<template>
  <div class="box_container">
    <div class="box_item">
      <div class="userInfo">
        <div class="text">
          <div class="name">
            <span>用户姓名: {{ obj.name }}</span>
            <span
              :style="
                state == 0
                  ? 'color:#5038FA'
                  : state == 1
                  ? 'color:red'
                  : state == 2
                  ? 'color:green'
                  : state == 3
                  ? 'color:#e8b501'
                  : ''
              "
            >
              <!-- {{
                state == 0
                  ? "已签约"
                  : state == 1
                  ? "未签约"
                  : state == 2
                  ? "洽谈中"
                  : state == 3
                  ? "合同临近"
                  : ""
              }} -->
            </span>
          </div>
          <div class="phone">
            用户手机号: {{ obj.mobile ? obj.mobile : "暂未填写" }}
          </div>
          <div class="id">
            用户身份证号: {{ obj.idCard ? obj.idCard : "暂未填写" }}
          </div>
        </div>
      </div>
      <div class="btn_item">
        <van-button
          color="linear-gradient(0deg, #6EAEFC, #6875F1)"
          round
          :to="{
            path: '/fangTan',
            query: { id: obj.id, type: 1 },
          }"
        >
          查看
        </van-button>
        <van-button
          color="linear-gradient(0deg, #0ECDE1, #2BD79F)"
          round
          :to="{
            path: '/fangTan',
            query: { id: obj.id, type: 2 },
          }"
        >
          访谈
        </van-button>
        <van-button
          color="linear-gradient(0deg, #FF9A31, #FFD385)"
          round
          :to="{
            path: '/fangTan',
            query: { id: obj.id, type: 3 },
          }"
        >
          合同
        </van-button>
        <van-button
          color="linear-gradient(0deg, #84F5DE, #56E8F2, #4CC5F8)"
          round
          :to="{
            path: '/fangTan',
            query: { id: obj.id, type: 4 },
          }"
        >
          支付
        </van-button>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    state: {
      type: Number,
      default() {
        return {};
      },
    },
    obj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
};
</script>
<style lang="less" scoped>
.box_container {
  .box_item {
    width: 346px;
    height: 157px;
    background: #ffffff;
    border-radius: 6px;
    margin: 14px;

    .userInfo {
      font-size: 16px;
      padding: 20px 16px 0 16px;

      .text {
        div {
          color: #666666;
          line-height: 27px;
        }

        .name {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .btn_item {
      display: flex;
      justify-content: space-evenly;
      /deep/ .van-button {
        width: 62px;
        height: 28px;
        margin-top: 10px;
        .van-button--round {
          border-radius: 20px;
        }
      }
    }
  }
}
</style>
